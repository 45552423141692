import React, { useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './ProductCategoriesNavigation.scss';

const ProductCategoriesNavigation = ({ location, topLevelParentChildren }) => {
  const [openCategories, setOpenCategories] = useState([]);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const currentCategorySlug = location.pathname.replace(`/${process.env.GATSBY_PRODUCTS_SLUG}/`, '');
  useEffect(() => {
    const openCatIndex = topLevelParentChildren.findIndex(item => {
      const regex = new RegExp(`^.+/${item.slug}/`);
      return currentCategorySlug.match(regex);
    });
    if (openCatIndex !== -1) setOpenCategories([openCatIndex]);
  }, []);
  const isActive = (slug) => {
    return currentCategorySlug === slug;
  };
  const toggleOpenCategories = (idx) => {
    const openCategoriesIndex = openCategories.indexOf(idx);
    const openCategoriesClone = [...openCategories];
    if (openCategoriesIndex === -1) {
      openCategoriesClone.push(idx);
    } else {
      openCategoriesClone.splice(openCategoriesIndex, 1);
    }
    setOpenCategories(openCategoriesClone);
  }
  const productCategoriesNavClassnames = [
    `product-categories-navigation`,
    `${mobileNavOpen ? 'mobile-open' : ''}`,
    `${topLevelParentChildren.length === 0 ? 'empty' : ''}`
  ];
  return (
    <div className={productCategoriesNavClassnames.join(' ')}>
      {/* eslint-disable-next-line */}
      <span
        className="product-categories-navigation-heading"
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        {location.pathname === "/our-products/brands/" ? "Brands" : "Product Categories" }
      </span>
      {topLevelParentChildren.length > 0 && topLevelParentChildren.map((child, index) => {
        const subCategorySlug1 = child.link.replace(`/${process.env.GATSBY_PRODUCTS_SLUG}/`, '');
        const hasChildren = child.thirdLevelCategories;
        const subcatlv1classNames = [
          'sub-category--lv1',
          `${openCategories.indexOf(index) >= 0 ? 'open' : ''}`,
          `${hasChildren ? 'has-children' : ''}`
        ];
        return (
          // eslint-disable-next-line
          <div
            key={index}
            className={subcatlv1classNames.join(' ')}
            onClick={() => toggleOpenCategories(index)}
          >
            <Link
              to={`/${process.env.GATSBY_PRODUCTS_SLUG}/${subCategorySlug1}`}
              className={isActive(subCategorySlug1) ? 'active' : ''}
            >
              {decodeEntities(child.name)}
            </Link>
            {hasChildren && (
              <div className="sub-category--lv2">
                {child.thirdLevelCategories.map(({node: lowerChild}, index) => {
                  const subCategorySlug2 = lowerChild.link.replace(`/${process.env.GATSBY_PRODUCTS_SLUG}/`, '');
                  return (
                    <Link
                      key={index}
                      to={`/${process.env.GATSBY_PRODUCTS_SLUG}/${subCategorySlug2}`}
                      className={isActive(subCategorySlug2) ? 'active' : ''}
                    >
                      {decodeEntities(lowerChild.name)}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProductCategoriesNavigation;
