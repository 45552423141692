import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { decodeEntities } from '../utils/htmlParse'
import './ProductCategoryBreadcrumbs.scss'

const Breadcrumbs = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressWpProductCategory {
        nodes {
          name: title
          slug
          link
        }
      }
    }
  `)
  const { allWordpressWpProductCategory, site } = data
  const catHierarchyElements = location.pathname
    .replace(`/${process.env.GATSBY_PRODUCTS_SLUG}/`, '')
    .split('/')
    .filter(el => el !== '') // trailing slash creates empty split element
    .map(el => allWordpressWpProductCategory.nodes.find(cat => cat.slug === el))
  return (
    <div className="product-category-breadcrumbs breadcrumbs">
      <div className="crumb">
        <Link to="/">Home</Link>
      </div>
      <div className="crumb">
        <Link to={`/${process.env.GATSBY_PRODUCTS_SLUG}/`}>Our Products</Link>
      </div>
      {catHierarchyElements &&
        catHierarchyElements.map((cat, index) => {
          const catLink = cat.link
          return (
            <div key={index} className="crumb">
              <Link to={catLink}>{decodeEntities(cat.name)}</Link>
            </div>
          )
        })}
    </div>
  )
}

export default Breadcrumbs
