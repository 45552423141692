import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from './Image';
import { decodeEntities } from '../utils/htmlParse';
import './SubCategoriesList.scss';
// Borrow styles from ProductList component
import './ProductList.scss';

const SubCategoriesList = ({ categories }) => {
  if (!categories) return null;
  return (
    <div className="product-subcategories-list product-list">
      {categories.map(({node: cat}, index) => {
        //const catLink = cat.link.replace(process.env.GATSBY_PRODUCTS_SLUG, '');
        return (
          <Link key={index} className="product-subcategory product-list-item" to={cat.link}>
            <Image className="product-list-item-image" image={cat.acf ? cat.acf.featureImage : null} />
            <span className="product-list-item-name">{decodeEntities(cat.name)}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default SubCategoriesList;
