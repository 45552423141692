import React from 'react';
import Image from './Image';
import './ProductList.scss';

const ProductList = ({ products }) => (
  <div className="product-list">
    {products.map(product => {
      const { title, featuredImage, slug } = product;
      return (
        <div className="product-list-item" key={slug}>
          <Image className="product-list-item-image" image={featuredImage} />
          <span className="product-list-item-name" dangerouslySetInnerHTML={{__html: title}} />
        </div>
      );
    })}
  </div>
);

export default ProductList;
