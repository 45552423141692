import React from 'react';
import { graphql } from 'gatsby';
import { ImageAndForm } from '../acf/ImageAndForm';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProductCategoryBreadcrumbs from '../components/ProductCategoryBreadcrumbs';
import ProductList from '../components/ProductList';
import ProductCategoriesNavigation from '../components/ProductCategoriesNavigation';
import SubCategoriesList from '../components/SubCategoriesList';
import { ProductSearch } from '../components/ProductSearch/ProductSearch.jsx';
import { Banner } from '../acf/Banner';
import { decodeEntities } from '../utils/htmlParse';
import './productcategory.scss';

const ProductCategory = ({ data, location, pageContext }) => {
  const { wordpressWpProductCategory: category, site, wordpressAcfOptions } = data;
  if (!category) return null;
  const { name: title, slug, description, acf = {} } = category;
  const { bannerImage, pageContent, types, materials, rating, product_range } = acf;
  const { title: siteTitle } = site.siteMetadata;
  const { options = {} } = wordpressAcfOptions;
  const { contactForm } = options;
  const {
    childCategories,
    topLevelParentCat,
    topLevelParentChildren,
    categoryProducts = true // will receive products assigned to this category
  } = pageContext;
  const {
    node: {
      name: topLevelParentTitle,
      description: topLevelParentDescription,
      acf: topLevelParentCatAcf = {}
    }
  } = topLevelParentCat;
  const { bannerImage: topLevelParentBannerImage } = topLevelParentCatAcf;
  return (
    <Layout location={location}>
      <SEO
        title={`${decodeEntities(title)} | ${decodeEntities(siteTitle)}`}
        pathname={location.pathname}
      />
      <Banner
        image={bannerImage || topLevelParentBannerImage}
        heading={topLevelParentTitle}
        headingTag="span"
        subtitle={topLevelParentDescription}
      />
      <section className="product-category-container">
        <div className="wrapper">
          <ProductCategoriesNavigation
            location={location}
            topLevelParentChildren={topLevelParentChildren}
          />
          <div className="product-category-content-container">
            <ProductCategoryBreadcrumbs location={location} />
            <h1
              className="product-category-heading"
              dangerouslySetInnerHTML={{__html: title}}
            />
            {pageContent && (
              <div
                className="product-category-content"
                dangerouslySetInnerHTML={{__html: pageContent}}
              />
            )}
            {(types || materials || rating) && (
              <div className="product-category-details">
                {types && (
                  <div className="product-category-details-grid-item product-category-types">
                    <h3 className="product-category-details-grid-item-heading">Types:</h3>
                    <p dangerouslySetInnerHTML={{__html: types}} />
                  </div>
                )}
                {materials && (
                  <div className="product-category-details-grid-item product-category-materials">
                    <h3 className="product-category-details-grid-item-heading">Materials:</h3>
                    <p dangerouslySetInnerHTML={{__html: materials}} />
                  </div>
                )}
                {rating && (
                  <div className="product-category-details-grid-item product-category-rating">
                    <h3 className="product-category-details-grid-item-heading">Rating:</h3>
                    <p dangerouslySetInnerHTML={{__html: rating}} />
                  </div>
                )}
              </div>
            )}
            <SubCategoriesList categories={childCategories} />
            {product_range?.length > 0 && (
              <div className="product-category-product-range">
                <h3 className="product-category-product-range-heading">
                  {/* eslint-disable-next-line */}
                  {decodeEntities(title)} Product Range
                </h3>
                <ProductList products={product_range} />
              </div>
            )}
          </div>
        </div>
      </section>
      <ProductSearch
        heading="We represent over 40 original equipment manufacturers."
        placeholder="Click here to search products"
        fancyLabel="Search"
        includeFilters={false}
      />
      {categoryProducts && (
        <ImageAndForm
          heading={contactForm.heading}
          content={contactForm.content}
          form="contact"
          image={contactForm.image}
          location={location}
        />
      )}
    </Layout>
  );
};

export default ProductCategory;

export const pageQuery = graphql`
  query ProductCategoryById($id: String!) {
    site {
      siteMetadata {
        title,
        functionsUrl
      }
    }
    wordpressAcfOptions {
      options {
        contactForm {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wordpressWpProductCategory(id: { eq: $id }) {
      name: title
      slug
      link
      acf {
        types
        materials
        rating
        pageContent
        product_range {
          title
        }
        bannerImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
